import {
	CardInfo,
	CreditCardJsInitResponse,
	GetTokenOnSuccess,
	GetTokenOnFailure,
	PaymentSystemReturnStatus,
	GetTokenSuccessResponse,
	GetTokenFailureResponse
} from "../../../types/v1/credit_card";
import {
	CreditCardSbPaymentInitData,
	TokenResponseFailure,
	GetTokenCallbackObject,
	TokenResponseSuccess,
	ComSbPaymentSystem,
	TokenRequest,
	TokenResponseResult
} from "../../../types/v1/credit_card/sbPayment";
import { formatExpire } from "../../common/formatExpire";
import { loadExternalScript } from "../../common/loadExternalScript";
import { sbPaymentEndpoints } from "../../endpoint/sbPayment";
import { getTokenError } from "../error/tokenError";
import { maskCardNumber, maskSecurityCode } from "../util";

declare global {
	interface Window {
		com_sbps_system: ComSbPaymentSystem;
	}
}

/**
 * トークン取得結果コードが成功系かどうかを確認する型ガード
 * @param res トークン取得結果
 * @returns resultCode === TokenResponseResult.OKの時、`TokenResponseSuccess`型とみなす
 */
export const isGetTokenCallbackSuccessObjectSbPayment = (
	res: GetTokenCallbackObject
): res is TokenResponseSuccess => {
	return res.result === TokenResponseResult.OK;
};

/**
 * カード情報を基にSBPaymentトークンを取得する
 *
 * `window.com_sbps_system.getToken`関数はコールバック関数でしか結果を取得できないため、Promiseを生成し、コールバック内で`resolve`か`reject`しています。
 * 必ず、そのような実装をキープしてください
 * @param cardInfo カード情報（番号、セキュリティコード、名前、有効期限など）
 * @param settings 決済システム設定情報（SBPayment用のMerchant ID、Service IDなどを含む）
 * @param cardDetail 決済システムサーバーから取得した登録済みカード詳細情報, cardInfo.card_idが存在する場合のみ
 * @param onSuccess トークン発行成功時のコールバック関数
 * @param onFailure トークン発行失敗時のコールバック関数
 * @returns トークン関連情報（成功または失敗の詳細を含む）
 */
export const creditSbPaymentGetToken = async (
	cardInfo: CardInfo,
	settings: CreditCardJsInitResponse<CreditCardSbPaymentInitData>,
	onSuccess?: GetTokenOnSuccess,
	onFailure?: GetTokenOnFailure
): Promise<GetTokenSuccessResponse | GetTokenFailureResponse> => {
	// SB Payment提供のjsファイル（generateToken.js）の読み込み
	await loadExternalScript(
		sbPaymentEndpoints.generateToken[settings.environment],
		"head",
		"sbps-js"
	);

	const format = formatExpire({
		year: { number: cardInfo.expires.year, length: 4 },
		month: { number: cardInfo.expires.month, padding: true }
	});

	const tokenRequest: TokenRequest = {
		merchantId: settings.merchant_id,
		serviceId: settings.service_id,
		ccNumber: cardInfo.number,
		ccExpiration: `${format.year}${format.month}`,
		...(settings.is_use_card_verification_value && {
			securityCode: cardInfo.security_code
		})
	};

	return new Promise((resolve, reject) => {
		const callback = (res: GetTokenCallbackObject) => {
			if (isGetTokenCallbackSuccessObjectSbPayment(res)) {
				// 型ガードを使用
				const successRes: TokenResponseSuccess = res;
				const successResponse: GetTokenSuccessResponse = {
					status: PaymentSystemReturnStatus.SUCCESS,
					token: successRes.tokenResponse.token,
					masked_security_code: maskSecurityCode(cardInfo.security_code),
					number: maskCardNumber(cardInfo.number),
					name: cardInfo.name,
					expires: { ...cardInfo.expires },
					brand: cardInfo.brand
				};
				window.LeghornPayment.card = successResponse;

				if (onSuccess) onSuccess(successResponse);
				resolve(successResponse);
			} else {
				// 失敗時の処理
				const failureRes: TokenResponseFailure = res;
				const failureResponse: GetTokenFailureResponse = {
					status: PaymentSystemReturnStatus.FAILURE,
					errors: getTokenError([failureRes.errorCode], settings)
				};
				window.LeghornPayment.card = failureResponse;

				if (onFailure) onFailure(failureResponse);
				reject(failureResponse);
			}
		};

		window.com_sbps_system?.generateToken(tokenRequest, callback);
	});
};

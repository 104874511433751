import { CreditAgency } from "./enum";

export enum TokenResponseResult {
	/** トークン取得に成功した場合 */
	OK = "OK",
	/** トークン取得に失敗した場合 */
	NG = "NG"
}

/** 初期化成功時のSB PAYMENTのレスポンス */
export interface CreditCardSbPaymentInitData {
	agency: CreditAgency.SB_PAYMENT;
	merchant_id: string;
	service_id: string;
}

/** SB PAYMENT提供js内の関数定義 */
export interface ComSbPaymentSystem {
	generateToken: (
		tokenRequest: TokenRequest,
		callback: (response: GetTokenCallbackObject) => void
	) => void;
}

/** SB PAYMENTトークン取得時のカード情報オブジェクト */
export interface TokenRequest {
	/** SbPayment発行の加盟店コード */
	merchantId: string;
	/** SbPayment発行のサービスコード */
	serviceId: string;
	/** カード番号 */
	ccNumber: string | number;
	/**
	 * カード有効期限
	 * YYYYMM
	 */
	ccExpiration: string;
	/** セキュリティーコード */
	securityCode?: string | number;
}

/** トークン取得成功時のコールバック関数の引数オブジェクト */
export interface TokenResponseSuccess {
	result: TokenResponseResult.OK;
	tokenResponse: {
		/** トークン */
		token: string;
		/** トークンキー */
		tokenKey: string;
		/** カード番号 */
		maskedCcNumber: string;
		/** カード有効期限 */
		ccExpiration: string;
		/** カードブランド */
		cardBrandCode: string;
	};
}

/** トークン取得失敗時のコールバック関数の引数オブジェクト */
export interface TokenResponseFailure {
	result: TokenResponseResult.NG;
	errorCode: string;
}

/** `com_sbps_system.generateToken`関数のコールバックオブジェクト */
export type GetTokenCallbackObject = TokenResponseSuccess | TokenResponseFailure;
